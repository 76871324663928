.boton {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: var(--btn-hover);
	color: rgb(255, 255, 255);
	font-weight: 500;
	cursor: pointer;
	text-transform: capitalize;
	transition: .5s ease all;
	position: relative;
	overflow: hidden;
}

.boton span {
	position: relative;
	z-index: 2;
	transition: .3s ease all;
}

/* primer estilo */

.boton.one::after {
	content: "";
	width: 100%;
	height: 300px;
	background: var(--btn-hover);
	position: absolute;
	z-index: 1;
	top: -300px;
	left: 0;
	transition: .6s ease-in-out all;
	border-radius: 0px 0px 300px 300px;
	border: 2px solid var(--btn-hover);
}

.boton.one:hover::after {
	top: 0;
}

/* segundo estilo */

.boton.two::after {
	content: "";
	width: 100%;
	height: 100%;
	background: var(--btn-hover);
	position: absolute;
	z-index: 1;
	top: -80px;
	left: 0;
	transition: .6s ease-in-out all;
}

.boton.two:hover::after {
	top: 0;
}

/* tercer estilo */

.boton.three::after {
	box-shadow: 0 0.5em 0.5em -0.4em var(--btn-hover);
	-webkit-transform: translateY(-0.25em);
	transform: translateY(-0.25em);

}

.boton.three:hover,
.boton.three:focus {
	box-shadow: #D74438 5px 5px;
	-webkit-transform: translateY(-0.25em);
	transform: translateY(-0.25em);
}

/*boton del formulario */

.formContent__btn {
	display: inline-flex !important;
	align-items: center !important;
	justify-content: center !important;
	background: var(--btn) !important;
	color: white !important;
	font-weight: 500 !important;
	border: none !important;
	cursor: pointer !important;
	text-transform: capitalize !important;
	transition: .5s ease all !important;
	position: relative !important;
	overflow: hidden !important;
	width: auto !important;
	padding: 16px 24px !important;
	display: flex !important;
	justify-content: flex-start !important;
}


.button-51 {
	background-color: transparent;
	border: 1px solid #000000;
	box-sizing: border-box;
	color: #ffffff;
	font-family: "Avenir Next LT W01 Bold", sans-serif;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	padding: 16px 23px;
	position: relative;
	text-decoration: none;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
}


.button-51:hover,
.boton.button-51:active {
	outline: 0;
}

.button-51:hover {
	background-color: transparent;
	cursor: pointer;
}

.button-51:before {
	background-color: var(--btn);
	content: "";
	border-radius: 6px;
	height: calc(100% + 3px);
	position: absolute;
	right: -7px;
	top: -9px;
	transition: background-color 300ms ease-in;
	width: 100%;
	z-index: -1;
}

.button-51:hover:before {
	background-color: var(--btn-hover);
	color: white;
}

.button-52 {
	font-size: 16px;
	font-weight: 200;
	letter-spacing: 1px;
	padding: 13px 20px 13px;
	outline: 0;
	border: 1px solid black;
	cursor: pointer;
	position: relative;
	background-color: rgba(0, 0, 0, 0);
	user-select: none;
	color: white;
	-webkit-user-select: none;
	touch-action: manipulation;
}

.button-52:after {
	content: "";
	background-color: var(--btn);
	width: 100%;
	z-index: -1;
	position: absolute;
	height: 100%;
	top: 7px;
	left: 7px;
	border-radius: 6px;
	transition: 0.2s;
}

.button-52:hover:after {
	top: 0px;
	left: 0px;
}

.button-86 {
	all: unset;
	width: 100px;
	height: 30px;
	font-size: 16px;
	background: transparent;
	border: none;
	position: relative;
	color: #f0f0f0;
	cursor: pointer;
	z-index: 1;
	padding: 10px 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
}

.button-86::after,
.button-86::before {
	content: '';
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: -99999;
	transition: all .4s;
}

.button-86::before {
	transform: translate(0%, 0%);
	width: 100%;
	height: 100%;
	background: #000000;
	border-radius: 10px;
}

.button-86::after {

	width: 45px;
	height: 45px;
	background: #244A8E;
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	border-radius: 50px;
}

.button-86:hover::before {
	transform: translate(5%, 15%);
	width: 100%;
	height: 110%;
}

.button-86:hover::after {
	border-radius: 10px;
	transform: translate(0, 0);
	width: 100%;
	height: 100%;
}

.button-86:active::after {
	transition: 0s;
	transform: translate(0, 5%);
}